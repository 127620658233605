import { IActionContext } from '@msdyn365-commerce/core';
import Cookies from 'universal-cookie';
export const getZipCode = (ctx: IActionContext): string | undefined => {
    //@ts-ignore
    const geolocationZipCode = ctx.requestContext.geoLocation?.zipCode ? ctx.requestContext.geoLocation.zipCode : ctx.requestContext.geoLocation?.res?.zipCode;
    const cookie = new Cookies();
    const zipcode = cookie.get('zip_code') ? cookie.get('zip_code') : geolocationZipCode;
    return zipcode ?? undefined;
};
export const getGeoLocationZipCode = (ctx: IActionContext): string | undefined => {
    //@ts-ignore
    const zipCode = ctx.requestContext.geoLocation?.zipCode ? ctx.requestContext.geoLocation.zipCode : ctx.requestContext.geoLocation?.res?.zipCode;
    return zipCode ?? null;
};
